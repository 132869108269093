import * as React from "react";
import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import { logIn, logOut } from "./lib/Auth";
import { getUserProfile } from "./lib/User";
import { getAllCycle } from "./lib/Cycle";
import { getAllEmployee, uploadEmployeeCSV } from "./lib/Employee";
import {
  postAnswerReviewee,
  postAnswerReviewer,
  getDetailFormReviewee,
  getDetailFormReviewer,
} from "./lib/Form";

import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Menu,
  theme,
  Avatar,
  Table,
  Spin,
  Modal,
  Radio,
  message,
} from "antd";
import styled from "styled-components";

import loginBG from "./images/login.svg";
import adminLogo from "./images/admin.svg";
import { ReactComponent as dashboardLogo } from "./images/dashboard.svg";
import { ReactComponent as cyclesLogo } from "./images/cycles.svg";
import { ReactComponent as formsLogo } from "./images/forms.svg";
import { ReactComponent as employeesLogo } from "./images/employees.svg";
import { ReactComponent as reportLogo } from "./images/report.svg";
import { ReactComponent as logoutLogo } from "./images/logout.svg";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route
          path='/dashboard'
          element={
            <RequireAuth>
              <LayoutDashboard />
            </RequireAuth>
          }
        >
          <Route path='main' element={<MainDashboard />} />

          {/* Admin */}
          <Route path='cycles' element={<Cycles />} />
          <Route path='forms' element={<Forms />} />
          <Route path='employees' element={<Employees />} />

          {/* Reviewee and reviewer */}
          <Route path='submit-review' element={<FormReviewee />} />
          <Route path='history' element={<History />} />

          {/* Reviewer */}
          <Route path='team-member' element={<TeamMember />} />
          <Route path='team-member/:id' element={<TeamMemberDetail />} />

          {/* Admin and reviewer */}
          <Route path='report' element={<Report />} />

          <Route path='logout' element={<Logout />} />
        </Route>

        <Route path='*' element={<NoMatch />} />
      </Routes>
    </AuthProvider>
  );
}

const storage = {
  getToken: () => window.localStorage.getItem("token"),
  removeToken: () => window.localStorage.removeItem("token"),
  setToken: (token) => window.localStorage.setItem("token", token),
};

const AuthContext = React.createContext(null);

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(""); //admin, reviewee, reviewer
  const [form, setForm] = useState({ formReviewee: [], formReviewer: [] });
  const [isUserloading, setUserLoading] = useState(true);

  useEffect(() => {
    const token = storage.getToken();
    if (!token) {
      setUserLoading(false);
      setUser(null);
    } else {
      getUser();
    }
  }, []);

  const getUser = () => {
    setUserLoading(true);
    getUserProfile()
      .then((data) => {
        setUser(data?.data);
        let role = "";

        if (data?.data?.role?.id === 1) {
          role = "admin";
          setRole(role);
          return;
        }

        if (data?.data?.form_reviewer?.length > 0) {
          role = "reviewer";
        } else {
          role = "reviewee";
        }
        setRole(role);
      })
      .then(getForm)
      .catch((error) => console.error(error))
      .finally(() => setUserLoading(false));
  };

  const getForm = () => {
    Promise.all([getDetailFormReviewee(), getDetailFormReviewer()]).then(
      ([formReviewee, formReviewer]) => {
        setForm({
          formReviewee: formReviewee.data,
          formReviewer: formReviewer.data,
        });
      }
    );
  };

  const logInUser = ({ email, password }) => {
    return logIn({ email, password })
      .then((data) => {
        storage.setToken(data.access_token);
      })
      .then(getUser)
      .then(() => navigate("/dashboard/main"))
      .catch((error) => console.error(error));
  };

  const logOutUser = () => {
    return logOut()
      .then(() => {
        setUser(null);
        storage.removeToken();
        navigate("/login");
      })
      .catch((error) => console.error(error));
  };

  const value = { user, isUserloading, logInUser, logOutUser, form, role };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children }) {
  const auth = useAuth();

  if (auth.isUserloading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 58 }} spin />;
    return (
      <Row justify={"center"} align='middle' style={{ height: "100vh" }}>
        <Col>
          <Spin
            indicator={antIcon}
            size={"large"}
            style={{ color: "gainsboro" }}
          />
        </Col>
      </Row>
    );
  }

  if (!auth.isUserloading && !auth.user) {
    return <Navigate to='/login' />;
  }

  return children;
}

const LoginBackground = styled.div`
  width: 100%;
  height: 100%;
  background: #438965 url(${loginBG}) no-repeat center;
`;

const LoginWrapper = styled(Row)`
  height: 100%;
  font-weight: normal;

  h1 {
    text-align: center;
  }

  input,
  input:focus,
  input:hover,
  .ant-input-password:focus,
  .ant-input-password:hover,
  .ant-input-affix-wrapper {
    border-color: #003443;
  }

  .ant-form-item-required::before {
    display: none !important;
  }
`;

const ButtonLogin = styled(Button)`
  height: 50px;
  font-size: 18px;
  background: #003443;
  color: #ffffff;

  &:hover {
    background: #003443;
    border-color: #003443 !important;
    color: #ffffff !important;
  }
`;

const Label = styled.h3`
  margin-bottom: 0px;
`;

function Login() {
  const auth = useAuth();
  const [isLoading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    auth
      .logInUser({ email: values.email, password: values.password })
      .finally(() => setLoading(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Row style={{ height: "100vh" }}>
      <Col lg={12} xs={0}>
        <LoginBackground />
      </Col>
      <Col lg={12} xs={24}>
        <LoginWrapper justify='center' align='middle'>
          <Col span={20}>
            <h1 style={{ marginBottom: "60px" }}>Login</h1>

            <h3 style={{ marginBottom: "40px" }}>
              Please fill in your unique login details below
            </h3>

            <Form
              name='basic'
              layout='vertical'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <Form.Item
                label={<Label>Email address</Label>}
                name='email'
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input style={{ height: "50px" }} />
              </Form.Item>

              <Form.Item
                label={<Label>Password</Label>}
                name='password'
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password style={{ height: "50px" }} />
              </Form.Item>

              <h3 style={{ textAlign: "right" }}>Forgot Password?</h3>

              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <ButtonLogin
                  htmlType='submit'
                  block
                  disabled={isLoading}
                  style={{ height: "50px", marginTop: "18px" }}
                >
                  Sign In
                </ButtonLogin>
              </Form.Item>
            </Form>
          </Col>
        </LoginWrapper>
      </Col>
    </Row>
  );
}

const AdminLogo = styled.div`
  width: 100%;
  height: 80px;
  padding: 25px 30px;

  h3 {
    display: inline;
    vertical-align: bottom;
    padding-left: 8px;
  }
`;

const MenuWrapper = styled(Menu)`
  li.ant-menu-item {
    height: 60px;
  }

  li.ant-menu-item-selected {
    background-color: #efefef !important;
    color: #003443 !important;
    position: relative;
  }

  li.ant-menu-item-selected:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 100%;
    background: #003443;
    left: 0px;
  }
`;

const AdminMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  .ant-avatar {
    margin-left: 10px;
    margin-right: 15px;
  }
`;

const AdminMenuContent = styled.div`
  p,
  span {
    display: block;
    text-align: right;
    line-height: 12px;
    margin: 0;
  }

  span {
    font-size: 12px;
    padding-top: 6px;
    color: #666666;
  }
`;

function LayoutDashboard() {
  const auth = useAuth();

  const [activeMenu, setActiveMenu] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setActiveMenu(location.pathname.replace("/dashboard/", ""));
  }, []);

  function capitalizeWord(element) {
    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
  }

  const navItemsAdmin = [
    {
      key: "main",
      icon: React.createElement(dashboardLogo),
      label: "Dashboard",
    },
    {
      key: "cycles",
      icon: React.createElement(cyclesLogo),
      label: "Cycles",
    },
    {
      key: "forms",
      icon: React.createElement(formsLogo),
      label: "Forms",
    },
    {
      key: "employees",
      icon: React.createElement(employeesLogo),
      label: "Employees",
    },
    {
      key: "report",
      icon: React.createElement(reportLogo),
      label: "Report",
    },
    {
      key: "logout",
      icon: React.createElement(logoutLogo),
      label: "Logout",
    },
  ];

  const navItemsReviewee = [
    {
      key: "main",
      icon: React.createElement(dashboardLogo),
      label: "Dashboard",
    },
    {
      key: "submit-review",
      icon: React.createElement(employeesLogo),
      label: "Submit Review",
    },
    {
      key: "history",
      icon: React.createElement(reportLogo),
      label: "History",
    },
    {
      key: "logout",
      icon: React.createElement(logoutLogo),
      label: "Logout",
    },
  ];

  const navItemsReviewer = [
    {
      key: "main",
      icon: React.createElement(dashboardLogo),
      label: "Dashboard",
    },
    {
      key: "team-member",
      icon: React.createElement(cyclesLogo),
      label: "Team Member",
    },
    {
      key: "submit-review",
      icon: React.createElement(employeesLogo),
      label: "Submit Review",
    },
    {
      key: "history",
      icon: React.createElement(formsLogo),
      label: "History",
    },
    {
      key: "report",
      icon: React.createElement(reportLogo),
      label: "Report",
    },
    {
      key: "logout",
      icon: React.createElement(logoutLogo),
      label: "Logout",
    },
  ];

  const navToRender = {
    admin: navItemsAdmin,
    reviewee: navItemsReviewee,
    reviewer: navItemsReviewer,
  };

  const navItems = navToRender[auth.role] || [];

  return (
    <Layout style={{ height: "100vh", background: "#ffffff" }}>
      <Sider
        breakpoint='lg'
        width={275}
        style={{ background: "#ffffff" }}
        collapsedWidth='0'
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <AdminLogo>
          <img src={adminLogo} alt='admin' />
          <h3>Admin</h3>
        </AdminLogo>
        <MenuWrapper
          theme='light'
          mode='inline'
          defaultSelectedKeys={["4"]}
          items={navItems.map(({ key, icon, label }) => ({
            key,
            icon,
            label,
          }))}
          onSelect={(menu) => {
            setActiveMenu(menu.key);
            navigate(`/dashboard/${menu.key}`);
          }}
          selectedKeys={[activeMenu]}
        />
      </Sider>
      <Layout style={{ minWidth: "350px" }}>
        <Header
          style={{
            padding: "0 20px",
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1>{capitalizeWord(activeMenu)}</h1>
          <AdminMenuWrapper>
            <AdminMenuContent>
              <p>{auth?.user?.name}</p>
              <span>{auth?.user?.role?.name}</span>
            </AdminMenuContent>
            <Avatar icon={<UserOutlined />} />
          </AdminMenuWrapper>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            minWidth: "300px",
            background: colorBgContainer,
            padding: "24px",
            overflowY: "auto",
          }}
        >
          {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
}

function MainDashboard() {
  return <div>MainDashboard</div>;
}

function Cycles() {
  const [cycles, setCycles] = useState([]);
  useEffect(() => {
    getAllCycle()
      .then((data) => {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const cycleMap = data?.data.map(
          ({ id, review_period, start_join_date, end_join_date }) => {
            const startMonth = new Date(start_join_date);
            const endMonth = new Date(end_join_date);
            return {
              key: id,
              id: id,
              periode: review_period,
              date: `${monthNames[startMonth.getMonth()]} - ${
                monthNames[endMonth.getMonth()]
              }`,
            };
          }
        );
        setCycles(cycleMap);
      })
      .catch((error) => console.error(error));
  }, []);

  const columns = [
    {
      title: "ID Cycle",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Periode Penilaian",
      dataIndex: "periode",
      key: "periode",
    },
    {
      title: "Tanggal Join",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <div>
      <h3>Daftar Siklus Penilaian</h3>
      <br />
      <Table dataSource={cycles} columns={columns} />
    </div>
  );
}

function Forms() {
  const dataSource = [
    {
      id: 1,
      name: "Form Reviewee",
      description: "dolor ipsum dolor sit amet",
      action: (
        <div>
          <span>Edit</span> <span>View</span>
        </div>
      ),
    },
    {
      id: 2,
      name: "Form Reviewer",
      description: "dolor ipsum dolor sit amet",
      action: (
        <div>
          <span>Edit</span> <span>View</span>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "ID Form",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nama Form",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deskripsi Form",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <div>
      <h3>Daftar Forms</h3>
      <br />
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
}

const ButtonImport = styled(Button)`
  height: 32px;
  width: 100px;
  font-size: 14px;
  background: #003443;
  color: #ffffff;
  float: right;
  margin: 0 0 12px 0;

  &:hover {
    background: #003443;
    border-color: #003443 !important;
    color: #ffffff !important;
  }
`;

const Buttonupload = styled(ButtonImport)`
  height: 36px;
  width: 200px;
  margin: 0;
  float: unset;
`;

const ModalImport = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
    padding-bottom: 28px;
  }
  .ant-modal-footer {
    text-align: center;
  }

  input[type="file"] {
    margin: 20px 0;
    width: 100%;
    height: 40px;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #555;
  }

  input[type="file"]::file-selector-button {
    background: #ffffff;
    border-radius: 4px;
    color: #003443;
    cursor: pointer;
    border: 1px solid #003443;
    transition: background 0.2s ease-in-out;
    float: right;
    height: 28px;
    margin: 0;
  }

  input[type="file"]::file-selector-button:hover {
    background: #003443;
    color: #ffffff;
  }
`;
function Employees() {
  const [employees, setEmployees] = useState([]);
  const [isModaOpen, setModalOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    getAllEmployeeAPI();
  }, []);

  const getAllEmployeeAPI = () =>
    getAllEmployee()
      .then((data) => {
        const employeesMap = data?.data.map(
          ({ employee_id, name, role, join_date, direct_superior }) => ({
            key: employee_id,
            id: employee_id,
            name: name,
            role: role.name,
            joinDate: join_date,
            supervisor: direct_superior,
            status: "aktif",
          })
        );
        setEmployees(employeesMap);
      })
      .catch((error) => console.error(error));

  const onUpload = () => {
    const data = new FormData();
    data.append("file_csv", file);

    setUploading(true);
    uploadEmployeeCSV(data)
      .then((resp) => {
        message.success(resp.message);
        setModalOpen(false);
      })
      .then(getAllEmployeeAPI)
      .catch((error) => console.error(error))
      .finally(() => setUploading(false));
  };

  const columns = [
    {
      title: "ID Employee",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "joinDate",
    },
    {
      title: "Direct Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  return (
    <React.Fragment>
      <h3>Daftar Karyawan</h3>
      <ButtonImport onClick={() => setModalOpen(true)}>Import</ButtonImport>
      <br />
      <Table
        dataSource={employees}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
      />
      <ModalImport
        title='File Upload'
        destroyOnClose
        open={isModaOpen}
        onOk={() => {}}
        onCancel={() => setModalOpen(false)}
        footer={
          <Buttonupload disabled={!file || isUploading} onClick={onUpload}>
            {isUploading ? "Uploading..." : "Upload"}
          </Buttonupload>
        }
      >
        <input
          type={"file"}
          accept={".csv"}
          onChange={(data) => setFile(data?.target?.files[0])}
        />
      </ModalImport>
    </React.Fragment>
  );
}

function Report() {
  const dataSource = new Array(12).fill({
    id: 809,
    name: "Bambang",
    title: "Developer",
    level: "Member",
    joinDate: "24/2/2020",
    supervisor: "Budi",
    status: "aktif",
  });

  const columns = [
    {
      title: "ID Employee",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "joinDate",
    },
    {
      title: "Direct Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  return (
    <div>
      <h3>Daftar Report</h3>
      <br />
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
}

const Information = styled.div`
  height: 250px;
  border-radius: 4px;
  width: 350px;
  margin: 10% auto;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 20px;
`;

function FormReviewee() {
  const auth = useAuth();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    setSubmitting(true);
    postAnswerReviewee(values)
      .then((resp) => {
        message.success(resp.message);
      })
      .then(() => window.location.reload())
      .finally(() => setSubmitting(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const questionToRender = auth.user?.form_reviewee?.question;
  const directSuperior = auth.user?.form_reviewee?.direct_superior;

  return (
    <div>
      <h1>{auth.user?.form_reviewee?.name}</h1>
      <br />
      {!questionToRender ? (
        <Information style={{ background: "#E0F2F2" }}>
          <p>
            Kamu sudah mengisi form penilaian.
            <br />
            <br />
            Silahkan lakukan one-on-one dengan direct supervisor anda
            <br />
            <br />
            <b>{directSuperior}</b>
          </p>
        </Information>
      ) : (
        <Form
          form={form}
          name='basic'
          layout='vertical'
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          {questionToRender.map(({ id, question, order, type }) => {
            if (type === "text") {
              return (
                <Form.Item
                  key={id}
                  label={`${order}. ${question}`}
                  name={`question ${order}`}
                  rules={[
                    {
                      required: true,
                      message: "your answer is required",
                    },
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 4 }} />
                </Form.Item>
              );
            }

            if (type === "number") {
              return (
                <Form.Item
                  key={id}
                  label={`${order}. ${question}`}
                  name={`question ${order}`}
                  rules={[
                    {
                      required: true,
                      message: "your answer is required",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value='1'>Below Target</Radio>
                    <Radio value='2'>Partially Meet Target</Radio>
                    <Radio value='3'>Meet Target</Radio>
                    <Radio value='4'>Exceed Target</Radio>
                  </Radio.Group>
                </Form.Item>
              );
            }
            return null;
          })}

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <ButtonImport
              htmlType='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </ButtonImport>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

function TeamMember() {
  const auth = useAuth();

  const dataSource = auth.user?.form_reviewer.map((reviewee) => {
    const selfReviewMap = {
      "Responded": (
        <span style={{ color: "green" }}>Responded</span>
      ),
      "Waiting to review": (
        <span style={{ color: "green" }}>Waiting to review</span>
      ),
      "Not ready yet": <span style={{ color: "red" }}>Not ready yet</span>,
    };

    const reviewStatusMap = {
      Reviewed: <span style={{ color: "green" }}>Reviewed</span>,
      "Not Yet": <span style={{ color: "red" }}>Not Yet</span>,
    };

    const actionMap = {
      "Not ready yet": <span style={{ color: "grey" }}>Submit Review</span>,
      "Waiting to review": (
        <Link to={`/dashboard/team-member/${reviewee.id}`}>
          <span style={{ color: "green" }}>Submit Review</span>
        </Link>
      ),
      Responded: <Link to={`/dashboard/team-member/${reviewee.id}`}>
        <span style={{ color: "green" }}>Details</span></Link>,
    };

    return {
      key: reviewee.id,
      id: reviewee.employee_id,
      name: reviewee.name,
      title: "Employee",
      joinDate: reviewee.join_date,
      selfReview: selfReviewMap[reviewee.answer],
      reviewStatus:
        reviewStatusMap[
          reviewee.reviewer_answer.length > 0 ? "Reviewed" : "Not Yet"
        ],
      action: actionMap[reviewee.answer],
    };
  });

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "joinDate",
    },
    {
      title: "Self Review",
      dataIndex: "selfReview",
      key: "selfReview",
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <div>
      <h3>Member List</h3>
      <br />
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
}

function TeamMemberDetail() {
  const auth = useAuth();
  const params = useParams();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    postAnswerReviewer(params.id, values)
      .then((resp) => {
        message.success(resp.message)
        setTimeout(() => {
          window.location.pathname = "/dashboard/team-member"
        }, 500)
      })
      .finally(() => setSubmitting(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formReviewer = auth.user?.form_reviewer || [];
  const activeFormReviewer = formReviewer.find(
    ({ id }) => id === Number(params.id)
  );

  const answersFromReviewee = activeFormReviewer.reviewee_answer || [];
  const answersFromReviewer = activeFormReviewer.reviewer_answer || [];

  const questionForReviewee = auth.form?.formReviewee[0]?.question || [];
  const questionForReviewer = auth.form?.formReviewer[0]?.question || [];

  const performanceMap = {
    1: "Below Target",
    2: "Partially Meet Target",
    3: "Meet Target",
    4: "Exceed Target",
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#F9F1F1",
          padding: "20px",
          borderRadius: "2px",
          marginBottom: "20px",
        }}
      >
        <h1>{activeFormReviewer.name} Self Review</h1>
        <ol style={{ margin: "20px 0", maxWidth: "700px" }}>
          {answersFromReviewee.map((_answer) => {
            return (
              <li key={_answer.question_id} style={{ marginBottom: "20px" }}>
                <b>{questionForReviewee[_answer.question_id - 1]?.question}</b>
                <br />
                {_answer.question_id !== 7
                  ? _answer?.answer
                  : performanceMap[_answer?.answer]}
              </li>
            );
          })}
        </ol>
      </div>
      {answersFromReviewer.length === 0 ? (
        <div
          style={{
            backgroundColor: "#E0F2F2",
            padding: "20px",
            borderRadius: "2px",
          }}
        >
          <h1>Member Review</h1>
          <Form
            form={form}
            name='basic'
            layout='vertical'
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            {questionForReviewer.map(({ id, question, order, type }) => {
              if (type === "text") {
                return (
                  <Form.Item
                    key={id}
                    label={
                      <b>
                        {order}. {question}
                      </b>
                    }
                    name={`question ${order}`}
                    rules={[
                      {
                        required: true,
                        message: "your answer is required",
                      },
                    ]}
                  >
                    <Input.TextArea autoSize={{ minRows: 4 }} />
                  </Form.Item>
                );
              }

              if (type === "number") {
                return (
                  <Form.Item
                    key={id}
                    label={
                      <b>
                        {order}. {question}
                      </b>
                    }
                    name={`question ${order}`}
                    rules={[
                      {
                        required: true,
                        message: "your answer is required",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value='1'>Below Target</Radio>
                      <Radio value='2'>Partially Meet Target</Radio>
                      <Radio value='3'>Meet Target</Radio>
                      <Radio value='4'>Exceed Target</Radio>
                    </Radio.Group>
                  </Form.Item>
                );
              }
              return null;
            })}

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <ButtonImport
                htmlType='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit
              </ButtonImport>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#E0F2F2",
            padding: "20px",
            borderRadius: "2px",
          }}
        >
          <h1>Member Review</h1>
          <ol style={{ margin: "20px 0", maxWidth: "700px" }}>
            {answersFromReviewer.map((_answer) => {
              const question = questionForReviewer.find(
                ({ id }) => id == _answer.question_id
              );
              return (
                <li key={_answer.question_id} style={{ marginBottom: "20px" }}>
                  <b>{question?.question}</b>
                  <br />
                  {_answer.question_id !== 10
                    ? _answer?.answer
                    : performanceMap[_answer?.answer]}
                </li>
              );
            })}
          </ol>
        </div>
      )}
    </div>
  );
}

function History() {
  const auth = useAuth();

  const questionForReviewee = auth.form?.formReviewee[0]?.question || [];
  const answersFromReviewee = auth.user?.history_answer || [];

  const performanceMap = {
    1: "Below Target",
    2: "Partially Meet Target",
    3: "Meet Target",
    4: "Exceed Target",
  };
  return (
    <div>
      {answersFromReviewee[1] ? (
        answersFromReviewee.map((answer) => {
          return (
            <div
              style={{
                lineHeight: "20px",
                background: "#F9F1F1",
                padding: "20px",
                borderRadius: "2px",
                marginBottom: "20px",
              }}
            >
              <h1>Self Review (Year {answer.year})</h1>
              <ol style={{ margin: "20px 0", maxWidth: "700px" }}>
                {answer.performance_review.map((_answer) => {
                  return (
                    <li
                      key={_answer.question_id}
                      style={{ marginBottom: "20px" }}
                    >
                      <b>
                        {questionForReviewee[_answer.question_id - 1]?.question}
                      </b>
                      <br />
                      {_answer.question_id !== 7
                        ? _answer?.answer
                        : performanceMap[_answer?.answer]}
                    </li>
                  );
                })}
              </ol>
            </div>
          );
        })
      ) : (
        <Information style={{ background: "#F9F1F1" }}>
          <p>
            Kamu belum mengisi form penilaian.
            <br />
            <br />
            Silahkan lakukan pengisian form Self Evaluation pada link berikut:
            <br />
            <br />
            <Link to='/dashboard/submit-review'>
              <b>Self Evaluation</b>
            </Link>
          </p>
        </Information>
      )}
    </div>
  );
}

function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.logOutUser();
  }, []);

  return <div>Loging out...</div>;
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}
