/* eslint-disable no-dupe-keys */
import API from "./Api";

function postAnswerReviewee(payload) {
    const body = new FormData()
    body.append("id", 1) // 1 (reviewee) |2 (reviewer)
    Object.values(payload).map((answer) => body.append("answers[]", answer))

  return API().post("/api/form/answer", body);
}

function postAnswerReviewer(employeeId, answers) {
  const body = new FormData()
    body.append("id", 2) // 1 (reviewee) |2 (reviewer)
    body.append("employee_id", employeeId) // 1 (reviewee) |2 (reviewer)
    Object.values(answers).map((answer) => body.append("answers[]", answer))

  return API().post("/api/form/answer", body);
}

function getDetailFormReviewee(){
  return API().get("/api/form/1")
}

function getDetailFormReviewer(){
  return API().get("/api/form/2")
}

export { postAnswerReviewee, postAnswerReviewer, getDetailFormReviewee, getDetailFormReviewer };
