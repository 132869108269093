import API from "./Api";

function logIn({ email, password }) {
  return API({ withCredential: false }).post("/api/login", {
    email,
    password,
  });
}

function logOut() {
  return API().post("/api/logout");
}

export { logIn, logOut };
