import axios from "axios";
import { message } from "antd";

// Drop older message if multiple message shown
message.config({ maxCount: 1 });

function API({ withCredential } = { withCredential: true }) {
  const token = window.localStorage.getItem("token");
  const instance = axios.create({
    baseURL: "https://thesis.jkt.city",
  });

  if (withCredential && token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  instance.interceptors.response.use(
    function (response) {
      // Response always 200
      if (response.data.code >= 400) {
        const { message: messageAPI } = response.data;
        message.error(messageAPI);
        return Promise.reject(messageAPI);
      }

      return response.data;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      message.error(error.message);
      return Promise.reject(error);
    }
  );

  return instance;
}

export default API;
