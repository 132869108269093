import API from "./Api";

function getAllEmployee({ page, perpage } = { page: 1, perpage: 50 }) {
  return API().get("/api/employee");
}

function uploadEmployeeCSV(file) {
    return API().post("/api/employee/uploadcsv", file);
  }

export { getAllEmployee, uploadEmployeeCSV };
